<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button/>
      <h5 class="pageTitle">Terms of Use</h5>
    </div>
    <hr>


    <div class="msg">
      <div class=""><p>PLEASE READ THE TERMS OF USE (“AGREEMENT”) CAREFULLY BEFORE
        USING THE SERVICES OFFERED BY MUNOVO LLC (“GOBLIN BANK” OR “COMPANY”). THIS AGREEMENT IS A CONTRACT
        BETWEEN YOU AND GOBLIN BANK. BY VISITING THE WEBSITE, USING THE APP, OR USING THE SERVICES IN ANY MANNER, YOU AGREE YOU HAVE READ
        AND AGREE TO BE BOUND BY AND A PARTY TO THIS AGREEMENT TO THE EXCLUSION OF ALL OTHER TERMS. IF THE TERMS OF THIS
        AGREEMENT ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS. ACCESS TO THE WEBSITE AND USE
        OF COMPANY’S SERVICES IS EXPRESSLY CONDITIONED UPON YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS OF THIS
        AGREEMENT, TO THE EXCLUSION OF ALL OTHER TERMS. YOU REPRESENT TO GOBLIN BANK YOU HAVE READ, UNDERSTOOD AND EXPRESSLY
        AGREE TO BE BOUND BY THIS AGREEMENT AND ALL ITS TERMS WHETHER YOU CREATE A GOBLIN BANK ACCOUNT OR SIMPLY BROWSE OR
        USE THE SERVICE. IF YOU DO NOT UNCONDITIONALLY AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU HAVE
        NO RIGHT TO USE THE WEBSITE, APP OR SERVICES.&nbsp;</p>
        <p>Please read below to learn the terms under which you may use our website, applications or other services
          offered by MUNOVO LLC (“Munovo”, “Goblin Bank”, “Company” “we” or “us”) (collectively the
          “Services'').&nbsp;</p>
        <p>These Terms of Use apply to all Users including representatives at schools, school districts and related
          entities and organizations who access or use the Services including administrators and teachers (each a
          “School”) as well as all other visitors, users and others, including students and their parents and legal
          guardians, who use the Services (such individuals and Schools, collectively “Users” or “you”).&nbsp;</p>
        <p>Please read this Agreement carefully before using the Services. By creating an account, using the Services or
          otherwise accessing the Services, you signify you have read, understood, and agree to be bound by this
          Agreement and to the collection and use of your information as set forth in Goblin Bank’s <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span>, otherwise you may not use the Services.&nbsp;</p>
        <p>Parents and guardians are responsible for the acts of children under the age of 16 using the Services. If a
          User is under the age of 16, they must have a parent or guardian’s consent prior to any use of Goblin Bank or its
          Services. If a parent or guardian fails to provide consent and understand and agree to these Terms of Use,
          that User under 16 years of age does not have permission to use Goblin Bank or its Services and any use by that
          User will be unauthorized.&nbsp;</p>
        <p>Our Services change from time to time which means the terms in this Agreement and our Privacy Policy may
          change too. If we do update this Agreement, we will post the new version of the Agreement on our website and
          update the “Effective Date” of this Agreement. Your continued use of the Services after any changes
          constitutes your acceptance of the new terms.&nbsp;</p>
        <p>Your use of the Services is subject to additional terms, conditions, policies, rules or guidelines applicable
          to the Services or certain features of the Services we post or provide to you and all such additional terms
          are incorporated by reference into and form a part of this Agreement.</p></div>
      <div class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>Use of the services</h2>
        <p>Users are allowed to use the Services subject to and in compliance with this Agreement and all applicable
          local, provincial, state, national and international laws, rules and regulations. If your access to the
          Services has been revoked previously, you are not allowed to access the Services again.</p>
        <p>The goblinbank.com website, domain name and any other linked pages, features, content or application
          services (including mobile application or other services) offered from time to time by Goblin Bank (collectively,
          the “Website”) are owned by Goblin Bank. Subject to this Agreement, Goblin Bank grants you permission to access and
          use the Services, solely for your own use, and not for the use or benefit of any third party. The term
          “Services” includes, without limitation, use of the website, any service we provide to you and the Content (as
          defined below) offered by Goblin Bank on the website. We reserve all rights not expressly granted to you in the
          Services and the Content (as defined below). We may change, suspend or discontinue the Services at any time,
          including the availability of any service, feature or Content. We may also impose limits on certain services
          and/or features or restrict your access to parts or all of the Services without notice or liability.&nbsp;</p>
        <p>Your account or sign up for an event or consultation gives you access to the Services. Where you sign up and
          are accessing the Services through your account you are responsible for that account and the activity on that
          account. If a Goblin Bank account was created at or because of School use for a student User (a “School account”),
          a feature of our Services is that a User may also access that same School account at home on the Internet.
          However, when a separate home account (“Home account”) is created, any information in the School account will
          become part of and will belong to the parent and/or student User of that Home account. Therefore,
          post-activation of a Home account integrates information from a School account and thereby governed by both
          this Agreement and our <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span>.</p>
        <p>You are responsible for maintaining the confidentiality of any account information, your password and for
          restricting access to your account and your computer. You may never use someone else’s account without
          permission. You are solely responsible for any activity in your use of the Services and in your account. If
          your account has been compromised or if you suspect unauthorized use of your account, notify us immediately.
          Your account should not be used for any other purpose than to access the Services. You acknowledge and agree
          we may preserve user information and may disclose user information if we are required to do so by law or if we
          believe, in good faith, that such preservation or disclosure is reasonably necessary to: (a) comply with legal
          process or requirements; (b) enforce our Terms of Use; (c) respond to claims that certain Content violates the
          rights of a third party; or (d) protect the rights, property, or personal safety of the Company, its users or
          the public. <strong>Without limiting the foregoing, parents, legal guardians, and school officials who create
            accounts for minor children understand they are responsible for the acts and activities of those children in
            connection with the use of the Services and that this Agreement and Goblin Bank’s Privacy Policy will apply to
            their use of the Services.</strong></p>
        <p>If you are signing up for Services and/or creating accounts on behalf of student(s), you represent and
          warrant you are a teacher or school administrator or otherwise authorized by a school, district or other
          authorized organization to register Goblin Bank accounts for or on behalf of students, or you are the parent of
          such student(s) or that you are of legal age to form a binding contract. A student User or User under the age
          of 16 must have parental consent and if such verified parental consent has been provided to Goblin Bank, Goblin Bank
          assumes such User’s parent or legal guardian has read, understood and agrees to this Agreement on behalf of
          that student or under-16 User. School personnel are responsible for obtaining any necessary approvals from
          their School authorities and administrators and parents before using the Services. If you are a teacher,
          parent, guardian or administrator, and you provide or allow your students or child to log in to the Services
          using a single sign on service (e.g. Google or Clever), as the adult User you are responsible for advising
          students or your child on the proper use of such single sign on provider.</p>
        <p>You are responsible for all of your activity in connection with your use of the Services. Hacking or harming
          the Services is prohibited. By using the Services, you agree you will not conduct, conspire to, participate or
          otherwise engage in fraudulent, abusive, or otherwise illegal activity and that any such activity will be
          grounds for termination of your right to access or use the Services. Specifically, you agree you will not (i)
          post or transmit, or cause to be posted or transmitted, any communication or solicitation designed or intended
          to obtain password, account, or private information from any other User of the Services; (ii) use the Services
          to violate the security of any computer network, crack passwords or security encryption codes, transfer or
          store illegal material (including material that may be considered threatening or obscene), or engage in any
          kind of illegal activity; (iii) copy, distribute, or disclose any part of the Services in any medium or form,
          including but not limited to by any automated or non-automated “scraping”; (iv) use any automated system,
          including but not limited to “spiders”, “robots”, “offline readers” etc., to access the Services or that
          otherwise interfere with the proper working of or place an unreasonable load on the Services' infrastructure;
          (v) run Maillist, Listserv, any form of auto-responder, or “spam” on the Services, or any processes than run
          or are activated while you are not logged on to the Services; (vi) attempt to interfere with, compromise the
          system integrity or decipher any transmissions to or from the servers running the Services; (vii) transmit any
          malicious software agents through the Services; (viii) use the Services for any commercial solicitation
          purposes; (ix) interfere with the proper working of the Services; (x) access any content on the Services
          through any technology or means other than those provided or authorized by the Services; (xi) bypass the
          measures used by Goblin Bank to prevent or restrict access to the Services, including but not limited to features
          that prevent or restrict use or copying of content or enforce limitations on the use of the Services or
          related content.</p>
        <h2>Acceptable use and code of conduct</h2>
        <p>Goblin Bank strives to keep our Services safe but cannot absolutely guarantee the security and safety of the
          Services given the very nature of the Internet and the persistence of bad actors. Because of this, we require
          your help to keep the Services safe and require the following commitments from you when using the
          Services:</p>
        <ul>
          <li>You will not use the Services to violate a person’s right to privacy or otherwise collect, use or disclose
            data, including personal information, about other users without their consent or for unlawful purposes.
          </li>
          <li>You will not bully, intimidate, or harass, any User or use the Services in any manner that is threatening,
            abusive, violent or harmful to any person or entity.
          </li>
          <li>You will not use the Services in any way to upload, post, transmit, email or otherwise distribute content
            that is: hate speech, discriminating, defamatory, threatening, pornographic or otherwise obscene, incites
            violence; contains nudity or graphic or gratuitous violence; or is otherwise objectionable as reasonably
            determined by Company.
          </li>
          <li>You will not use the Services in any manner that would disparage the Company.</li>
          <li>You will not impersonate a Company employee, or any other person, or falsely state or otherwise
            misrepresent your affiliation with any person or entity.
          </li>
          <li>You will not use the Services in a manner that is harmful to minors. Without limiting the foregoing, you
            will not transmit or post any content anywhere on the Services, including any User Content, that violates
            child welfare laws, pornography laws or that otherwise violates any child sexual exploitation laws.
          </li>
          <li>You will not use the Services for any unlawful purpose or that is not in accordance with these Terms of
            Use. You agree not to access or use Goblin Bank or any Goblin Bank Services for any purpose that is illegal or
            beyond the scope of the Services’ intended use (in Goblin Bank’s sole judgment).
          </li>
        </ul>
        <h3>Fees and payment</h3>
        <p>There is a fee for some of our Services. Where the fee is a subscription, we may, in our sole discretion,
          change the fee at the end of your subscription period provided we first notify you by email. Where the fee is
          for something other than a subscription, we will notify you in advance of any rate changes. All fees are
          payable in accordance with the payment terms applicable at the time of payment. We may offer some promotional
          trials or time-limited discounts for certain Services from time to time. If you sign up or register through a
          trial, promotional or discount event, your rights of use will be subject to and limited by the terms of the
          trial, promotion or discount offer and will terminate or renew in accordance with the terms of the offer or
          any additional terms communicated related to the trial, promotion or discount offer. If you do not pay fees
          applicable to the Services you may not use or access those paid Services features. Unless otherwise expressly
          stated in the policies applicable to a Service, any fees paid for Services are non-refundable.</p>
        <p>Where a Service has a fee, you must use either a credit card or other acceptable or approved payment method
          to activate and maintain that Service. You will be responsible for the fees and all applicable taxes for any
          Service ordered. If we do not receive payment from your credit card or payment method, you agree to pay all
          amounts due hereunder upon demand and will be solely responsible for any dispute with your payment provider.
          Should your credit card or payment method be denied, we reserve the right to recharge the credit card or
          payment method for any outstanding amounts. You will not be charged more than the amount of the fees for the
          Service(s) you have purchased.&nbsp;</p>
        <p>Note, Goblin Bank does NOT directly store credit card information - all credit card information and transactions
          are stored by our payment provider, <a target="_blank" rel="noopener" href="https://stripe.com/">Stripe</a>.
          Stripe is a third-party service provider and processes payment on our behalf. You agree in the event Stripe
          experiences a data breach affecting your information through no fault of Goblin Bank, Goblin Bank will in no way be
          responsible or liable to you for any such breach. Please <a target="_blank" rel="noopener"
                                                                      href="https://stripe.com/docs/security">click
            here</a> for more information about how Stripe processes your information.</p>
        <h2>Goblin Bank content — Intellectual Property rights</h2>
        <p>The Services, including all content, features, and functionality thereof, are owned by Goblin Bank and are
          protected by United States and international copyright, trademark and other intellectual property or
          proprietary rights laws. The Services and all content, features and functionality are intended solely for the
          personal, non-commercial use by our Users and may only be used in accordance with these Terms. All past,
          present and future content, features and functionality of the Services, the software, hardware and technology
          used to provide the Services, User interfaces, materials displayed or performed on the Services (including,
          but not limited to text, graphics, articles, photographs, images, illustrations and the design, structure,
          sequence and “look and feel” of the Services (the “Content”), and all other intellectual property, including
          any Company trademarks, service marks, logos, or any other trade names, trademarks, service marks and other
          distinctive or proprietary brand features of the Services are protected by copyright, trademark or other
          intellectual property or proprietary laws. You agree your use of the Services does not give you ownership of
          any intellectual property or other proprietary rights in the Services or the Content and as between you and
          the Company, all such rights in the Services and Content, which does not include User Content (as defined
          below), are owned by and the property of the Company or its licensors. The Services are protected by copyright
          pursuant to Canadian and U.S. copyright laws, international conventions and other intellectual property laws.
          You may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative
          works based on, distribute, perform, display, or in any way exploit, any of the Content, software, materials
          or other aspects of the Services in whole or in part.</p>
        <p>In the course of using the Services, you and other Users may provide content or information which may be used
          by Goblin Bank to help support and provide the Services (“User Content”). For Goblin Bank to provide the Services, you
          hereby grant to Goblin Bank a limited, non-exclusive, sublicensable (as necessary to perform the Services)
          worldwide, royalty-free, and transferable right and license to: (i) use, host, copy, store, distribute,
          publicly perform and display, publish (in whole or in part), modify, and create derivative works from (such as
          changes we make so your content functions appropriately with the Services) such User Content as necessary to
          (a) provide, improve and make the Services available to you and other Users including through future media in
          which the Services may be distributed; (ii) use and disclose metrics and analytics regarding User Content in
          an aggregate or other non-personally identifiable manner (e.g., improving the Services, business development
          or marketing purposes); (iii) use, modify, prepare derivative works, publish, distribute and sublicense
          Feedback without compensation to you; (iv) process User Content that has been de-identified for product
          development, research or other lawful purposes.</p>
        <p>You agree you will not and will not allow a third party (whether or not for your benefit) to: (i) run,
          license, rent, lease, loan, distribute or sell access to the Services or Content; (ii) build or support
          (and/or assist a third party in building or supporting) products or services that compete with the Services or
          use similar ideas, features, functions, interface or graphics of the Services; (iii) use, store, copy,
          reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, create derivative
          works from, display, license, sell or otherwise exploit the Services or Content for any purposes other than as
          expressly authorized by this Agreement; (iv) decompile, reverse engineer, disassemble, or otherwise attempt to
          obtain the source code of the Services or Content; (v) circumvent, disable, or otherwise interfere or alter
          security related tools or features; (vi) remove, obscure or alter any copyright, logo, trademark, or other
          legal notices or otherwise use the Services in a manner that creates the impression the Services or Content
          belongs to any entity other than Goblin Bank.</p>
        <p>Company will not, under any circumstances, be liable in any way for any Content, including, but not limited
          to, any errors or omissions in any Content, or any loss or damage of any kind incurred in connection with use
          of or exposure to any Content posted, emailed, accessed, transmitted, or otherwise made available via the
          Services.</p>
        <p>You understand all information transmitted through the Services is the sole responsibility of the User from
          which such content originated and Goblin Bank is not and cannot be held liable for errors or omissions in any User
          Content. Goblin Bank cannot guarantee the authenticity of data which Users may provide about themselves. You
          acknowledge all Content accessed while using the Services is at your own risk and you are solely responsible
          for any damage or loss to any party resulting therefrom. Users acknowledge the Company reserves the right but
          is not obligated to pre-screen, filter, edit, remove, refuse to accept, post, display, or transmit any User
          Content through or on the Services in whole or in part any time for any or no reason without notice and
          without liability of any kind.&nbsp;</p>
        <p>Company’s automated systems may analyze your User Content (including emails) to provide you with personally
          relevant product features and for spam and malware detection. This analysis may occur when Content is sent,
          received or stored. Goblin Bank will not analyze any personal information in Student Data for the purpose of
          providing behaviourally-targeted advertising to students or parents. The foregoing shall not be construed to
          prohibit Goblin Bank from marketing or advertising directly to parents to offer customized student learning
          opportunities, game progress, or to recommend educational products or services so long as the recommendations
          are not based in whole or part on payment or other considerations from a third party.&nbsp;</p>
        <p>To help us improve the Services, from time to time we may conduct surveys, inquiries or submit requests for
          feedback through the Services or Users may submit feedback, comments or suggestions for improvements to the
          Services (in written, oral or any other form) (“Feedback”). Any survey, inquiries or request for Feedback is
          optional and will not prevent access to or impede the Services. If you choose to respond to a survey, your
          response(s) will be deidentified and aggregated with other responses. We will not identify your response(s)
          and will only use the responses in an anonymized or aggregated manner. When providing Feedback, Users assign
          to Goblin Bank all right, title and interest in any Feedback. Nothing in this Agreement restricts the Company’s
          right to use, profit from, disclose, publish, keep secret, or otherwise exploit Feedback without compensating
          or crediting the User or the individual providing such Feedback.</p>

        <h2>Privacy</h2>
        <p>For information about Goblin Bank’s treatment of personally identifiable information (“personal information”),
          please review our current <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span>,
          which is hereby incorporated by reference. Your acceptance of this Agreement constitutes your acceptance and
          agreement to be bound by our <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span>.
          Note, records that are: (1) directly related to a student, and (2) maintained by a School or other such
          institution or by a party acting for the School or institution may be “Education Records” protected by the
          Family Educational Rights and Privacy Act (“FERPA”). FERPA provides that a School may disclose personally
          information from these Records to a provider such as Goblin Bank to perform an institutional service or function
          where there is a legitimate educational interest in the Records in certain circumstances. For more information
          about Goblin Bank’s compliance with the guidelines of FERPA, please refer to the “Goblin Bank &amp; FERPA” section of
          our <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span>.</p>
        <h2>Third party services</h2>
        <p>The Services may contain links to third party websites or services (“Third Party service(s)”) not owned or
          controlled by Goblin Bank, or our Services may be accessed by logging in through a Third Party service. The
          inclusion of Third Party services does not imply affiliation or endorsement of such services by Goblin Bank.
          Goblin Bank does not control these Third Parties or their services and therefore when you access or use their
          services, you do so at your own risk and agree without limitation that Goblin Bank is not responsible for such
          Third Party services. You represent and warrant you have read and agree to be bound by all applicable
          policies, in addition to your obligations under this Agreement. Goblin Bank has no control over, and assumes no
          responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in by any
          Third Party. In addition, Goblin Bank will not and cannot monitor, verify, censor or edit the content of any Third
          Party or their service(s). By using Goblin Bank’s Services, you expressly relieve and hold us harmless from any
          and all liability arising from your use of any Third Party service(s).</p>
        <p>Your interactions with organizations and/or individuals found on or through the Services, including payment
          and delivery of goods or services, and any other terms, conditions, warranties or representations associated
          with such dealings, are solely between you and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with any online or offline transaction with
          any of these third parties. You agree Goblin Bank shall not be responsible or liable for any loss or damage
          incurred as the result of any such dealings. If there is a dispute between participants on this site, or
          between Users and any third party, you understand and agree Goblin Bank has no interest therin and is under no
          obligation to become involved.&nbsp;</p>
        <h2>Indemnity</h2>
        <p>You agree to defend, indemnify and hold harmless Goblin Bank and its subsidiaries, agents, licensors and
          affiliates, and their employees, contractors, agents, officers and directors from claims, damages,
          obligations, losses, cost or debt and expenses (including but not limited to attorney’s fees) arising from:
          (i) your use of and access to the Services, including any data or content transmitted by you; (ii) your
          violation of any term of this Agreement, including but not limited to your breach of any representations or
          warranties; (iii) your violation of any third party right, including but not limited to any right of privacy
          or intellectual property rights; (iv) any content or information that is submitted via your account or use of
          the Services; or (v) any other party’s access and use of the Services with your account credentials.</p>
        <h2>Warranty disclaimer</h2>
        <p>You acknowledge that Company has no control over, and no duty to take any action regarding: which users gain
          access to the Services; what Content you access via the Services; what effects the Content may have on you;
          how you may interpret or use the Content; or what actions you may take as a result of having been exposed to
          the Content. You release Company from all liability for you having acquired or not acquired Content through
          the Services. The Services may contain, or direct you to websites containing information or content found
          inappropriate or offensive by some users (unaffiliated content on social media platforms, e.g., YouTube and
          Facebook). Goblin Bank makes no representations or warranties regarding any content contained in or accessed
          through the Services, and Goblin Bank is not and will not be responsible or liable for the accuracy, copyright
          compliance legality or decency of material contained in or accessed through the Services. THE SERVICES,
          CONTENT, WEBSITE, PRODUCTS AND SERVICES OBTAINED THROUGH THE WEBSITE, AND ANY SOFTWARE ARE PROVIDED ON AN “AS
          IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES
          WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY
          LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
        <p>GOBLIN BANK MAKES NO REPRESENTATION OR WARRANTY THE SERVICES (1) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS OR
          BE TO YOUR LIKING; (2) WILL BE TIMELY, SECURE, ACCURATE, FREE FROM ERRORS OR LOSS, UNINTERRUPTED OR THE
          SERVICES ARE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS; (3) WILL BE CORRECTED, REPAIRED OR OTHERWISE
          RESTORED FOR ANY DEFECTS OR ERRORS THAT OCCUR - SOME FEATURES ARE EXPERIMENTAL AND HAVE NOT BEEN TESTED.</p>
        <p>NO ADVICE OR INSTRUCTION, WHETHER ORAL OR WRITTEN, OBTAINED BY THE USER FROM COMPANY SHALL CREATE ANY
          WARRANTY NOT EXPRESSLY STATED IN THESE TERMS AND CONDITIONS OF USE.</p>
        <h2>Limitation of liability</h2>
        <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL COMPANY OR ITS SUPPLIERS, OR THEIR
          RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE WITH RESPECT TO THE WEBSITE OR THE SERVICES OR
          THE SUBJECT MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR OTHER LEGAL OR
          EQUITABLE THEORY (I) FOR ANY AMOUNT IN THE AGGREGATE IN EXCESS OF THE GREATER OF $100 OR THE FEES PAID BY YOU
          TO COMPANY HEREUNDER DURING THE 12-MONTH PERIOD PRECEDING THE APPLICABLE CLAIM; (II) FOR ANY INDIRECT,
          INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER; (III) FOR DATA LOSS OR COST OF
          PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; OR (IV) FOR ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL. SOME
          STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
        <p>WHERE A JURISDICTION DOES NOT ALLOW LIMITATION ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
          CERTAIN DAMAGES, SOME OF THE ABOVE DISCLAIMERS, EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH
          CASES, COMPANY’S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
        <h2>Termination</h2>
        <p>This Agreement shall remain in force and effect while you use the Services. You may terminate your use of the
          Services at any time. Goblin Bank may terminate or suspend your access to the Services or your account at any
          time, for any reason, without warning, which may result in the forfeiture and destruction of all information
          associated with your account. Goblin Bank may also terminate or suspend any and all Services immediately, without
          prior notice or liability, if you breach any of the terms or conditions of this Agreement or of our <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span>. Upon termination of your account, your right to use the Services, access the website, and any
          Content will immediately cease, including, without limitation, ownership provisions, warranty disclaimers, and
          limitations of liability.</p>
        <h2>Miscellaneous</h2>
        <p>The failure of either party to exercise, in any respect, any right provided for herein shall not be deemed a
          waiver of any further rights hereunder. Company shall not be liable for any failure to perform its obligations
          hereunder where such failure results from any cause beyond Company’s reasonable control, including, without
          limitation, mechanical, electronic or communications failure or degradation. If any provision of this
          Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the
          minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and
          enforceable. This Agreement is not assignable, transferable or sublicensable by you except with Company’s
          prior written consent. Company may transfer, assign or delegate this Agreement and its rights and obligations
          without consent. Both parties agree this Agreement is the complete and exclusive statement of the mutual
          understanding relating to the subject matter of this Agreement, and all modifications must be in writing
          signed by both parties, except as otherwise provided herein. No agency, partnership, joint venture, or
          employment is created as a result of this Agreement and you do not have any authority of any kind to bind the
          Company in any respect whatsoever. Headings for each section have been included above for your convenience,
          but such headings do not have any legal meaning, and may not accurately reflect the content of the provisions
          they precede. Except as expressly set forth in this Agreement, you and Company agree there are no third party
          beneficiaries intended under this Agreement.</p>
        <h2>Governing law</h2>
        <p>This Agreement shall be governed by and construed in accordance with the laws of the Virginia, USA
          without regard to the conflict of laws provisions thereof. Any dispute arising from or relating to the subject
          matter or this Agreement shall be finally settled by arbitration in Virginia, USA, using the English
          language in accordance with the Arbitration Act of Virginia, USA. Notwithstanding the foregoing, each party shall
          have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable
          relief at any time. For all purposes of this Agreement, the parties consent to the exclusive jurisdiction and
          venue in the courts of Virginia, USA.</p>
        <h2>Contact</h2>
        <p>If you have any questions, complaints, or claims with respect to the Services, you may contact us at
          privacy@goblinbank.com, by mail at 10042 Wood Sorrels Ln, Burke, VA, USA, 22015.</p>
        <p></p>
        <p><strong>Last Modified Date: March 31, 2022</strong></p></div>
    </div>


  </div>
</template>

<script>


export default {
  name: 'TermsOfUse',
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {
    document.title = "Terms of Use";
  }
}
</script>

<style scoped>
.msg {
  margin: 20px 10px 10px 10px;
  padding: 10px;
  font-size: 13px;
}

section {
  margin-bottom: 30px;
}

hr {
  margin-top: 5px;
}


</style>